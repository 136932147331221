<template>
  <div class="comment">
    <div class="scord mt20 df aic fdc p20 bsbb">
      <div class="num df aic jcsb p10 bsbb">
        <div class="df aic jcsb">
          <div class="df aic">
            <div style="color: red" class="fz50 mr10">4.6分</div>
            <div class="df fdc">
              <p class="fz16 mb10" style="color: #ddd">高于附近92%的商家</p>
              <div class="df aic fz16">
                <van-icon name="star" color="red" />
                <van-icon name="star" color="red" />
                <van-icon name="star" color="red" />
                <van-icon name="star" color="red" />
                <van-icon name="star" />
              </div>
            </div>
          </div>
          <div class="df aic jcsa fz16">
            <div class="df fdc aic mr20">
              <p style="color: #ddd" class="mb10">质量</p>
              <p>4.6</p>
            </div>
            <div class="df fdc aic mr20">
              <p style="color: #ddd" class="mb10">包装</p>
              <p>4.6</p>
            </div>
            <div class="df fdc aic">
              <p style="color: #ddd" class="mb10">配送满意度</p>
              <p>92%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tags df aic jcsb fz16">
        <p class="active">全部24</p>
        <p>最新</p>
        <p>好评22</p>
        <p>近期差评2</p>
        <p>有图/视频2</p>
      </div>
      <div class="radio mt20">
        <div class="df aic">
          <van-radio-group v-model="checked">
            <van-radio
              name="1"
              icon-size="16px"
              checked-color="#ee0a24"
            ></van-radio>
          </van-radio-group>
          <p class="fz16" style="margin-left: 10px; color: #ddd">
            只看有内容评价
          </p>
        </div>
      </div>
    </div>
    <div class="splice"></div>
    <div class="content p15">
      <div class="item" v-for="(v, i) in commendList" :key="i">
        <div class="user df">
          <img :src="v.user_imgs" />
          <div class="df fdc p10 bsbb">
            <p class="fz20 fw7 mb10">{{ v.user_name }}</p>
            <p class="fz16" style="color: #999">2022-12-16</p>
          </div>
        </div>
        <div class="start df aic fz16 mt20 mb20" style="color: #ddd">
          <p class="mr10">商务5星</p>
          <p>质量5星</p>
        </div>
        <div class="pics df fdc">
          <div class="df mb20">
            <img src="../../assets/images/xg/item.png" class="mr20" />
            <img src="../../assets/images/xg/item.png" />
          </div>
          <img src="../../assets/images/xg/item.png" />
        </div>
        <div class="tickes fz16 mt40">
          <span class="mb20">【收藏门店】免费送随机单品果盒一盒/250g</span>
        </div>
        <div class="tickes fz16 mt40">
          <span>进口车厘子一份约300g</span>
        </div>
      </div>
      <!-- <div class="item">
        <div class="user df">
          <img src="../../assets/images/xg/item.png" />
          <div class="df fdc p10 bsbb">
            <p class="fz20 fw7 mb10">匿名用户</p>
            <p class="fz16" style="color: #999">2022-12-16</p>
          </div>
        </div>
        <div class="start df aic fz16 mt20 mb20" style="color: #ddd">
          <p class="mr10">商务5星</p>
          <p>质量5星</p>
        </div>
        <div class="pics df fdc">
          <div class="df mb20">
            <img src="../../assets/images/xg/item.png" class="mr20" />
            <img src="../../assets/images/xg/item.png" />
          </div>
          <img src="../../assets/images/xg/item.png" />
        </div>
        <div class="tickes fz16 mt40">
          <span class="mb20">【收藏门店】免费送随机单品果盒一盒/250g</span>
        </div>
        <div class="tickes fz16 mt40">
          <span>进口车厘子一份约300g</span>
        </div>
      </div> -->
      <!-- <div class="recomend fz16 p20 bsbb">
        <p class="fw7 mb10">商家回复</p>
        <p>亲，以后我们改进！</p>
      </div> -->
      <div class="footer"></div>
    </div>
  </div>
</template>

<script>
import { getAllEvaluate } from "@/utils/api.js";
export default {
  data() {
    return {
      checked: false,
      commendList: [],
      id:'',
    };
  },
  methods: {
    getData() {
      getAllEvaluate({ page: 1, page_size: 10, store_id:this.id}).then((res) => {
        this.commendList = res.data.list;
        console.log(res,'ppp');
      });
    },
  },
  created() {
    this.id = this.$route.query.id
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.comment {
  .scord {
    border-top: 1px solid #ddd;
    height: 260px;
    background: #fff;

    > div {
      width: 100%;

      > div {
        width: 100%;
      }
    }

    .tags {
      p {
        padding: 10px 16px;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #f2f2f2;

        &.active {
          background-color: #ace0b3;
          color: #55bb49;
        }
      }
    }
  }

  .splice {
    width: 100%;
    height: 50px;
    background-color: #f2f2f2;
  }

  .content {
    background-color: #fff;
    height: 100%;

    .item {
      border-bottom: 1px solid #ddd;
      padding: 20px 0;
      height: 810px;

      .user {
        img {
          width: 100px;
          height: 100px;
          margin-right: 20px;
        }
      }

      .pics {
        img {
          width: 235px;
          height: 235px;
        }
      }

      .tickes {
        span {
          border: 1px solid #ddd;
          padding: 12px;
          box-sizing: border-box;
          border-radius: 16px;
        }
      }
    }

    .recomend {
      margin-top: 20px;
      width: 710px;
      height: 110px;
      border-radius: 10px;
      background: rgba(216, 216, 216, 1);
    }

    .footer {
      height: 100px;
      background: #fff;
    }
  }
}
</style>